<template>
  <div>
    <v-card max-width="1000" class="mx-auto box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">{{
          plan ? 'Add Meal Plan Details' : 'Your Goals'
        }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-stepper class="elevation-0" v-model="step">
          <v-stepper-header>
            <!-- <v-stepper-step :complete="step > 1" step="1">Goals</v-stepper-step> -->
            <!-- <v-divider></v-divider> -->
            <v-stepper-step step="1">Mealplan Details</v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Primary Goal -->
            <!-- <v-stepper-content step="1">
              <v-card
                class="mb-5 step-energy-dialog"
                data-step="3"
                flat
              >
                <v-card-text>
                  <v-form ref="goalForm">
                    <v-row>
                      <v-col cols="8">
                        <template v-if="plan">
                          <v-text-field
                            v-model="data.client"
                            label="Client Name"
                            outlined
                            dense
                          ></v-text-field>

                           <v-text-field
                            v-model.number="data.age"
                            :rules="[rules.required]"
                            label="Age"
                            type="number"
                            outlined
                            dense
                          ></v-text-field>

                          <v-radio-group 
                            :rules="[rules.required]"
                            v-model="data.gender" 
                            label="Gender Assigned at Birth: " 
                            class="mt-0" 
                            dense
                            row 
                          >
                            <v-radio label="Male" value="male"></v-radio>
                            <v-radio label="Female" value="female"></v-radio>
                          </v-radio-group>
                        </template>

                        <v-select
                          :label="plan ? 'Primary goal' : 'What is your primary goal?'"
                          :rules="[rules.required]"
                          v-model="data.goal"
                          item-value="value"
                          item-text="text"
                          :items="goals"
                          outlined
                          dense
                        ></v-select>

                        <v-text-field
                          :label="plan ? 'Height (in cm)' : 'Your Height (in cm)'"
                          v-model.number="data.height"
                          :rules="[rules.required]"
                          type="number"
                          suffix="cm"
                          outlined
                          dense
                        ></v-text-field>

                        <v-text-field
                          :label="plan ? 'Weight (in kg)' : 'Your Weight (in kg)'"
                          v-model.number="data.weight"
                          :rules="[rules.required]"
                          type="number"
                          suffix="kg"
                          outlined
                          dense
                        ></v-text-field>

                        <v-text-field
                          :label="plan ? 'Body Mass Index (BMI) is' : 'Your Body Mass Index (BMI) is'"
                          :value="Math.round(bmi)"
                          suffix="kg/m2"
                          outlined
                          readonly
                          dense
                        ></v-text-field>

                        <v-text-field
                          label="Your Goal Weight (in kg)"
                          v-model="data.goalWeight"
                          :rules="[rules.required]"
                          v-if="!plan"
                          suffix="kg"
                          outlined
                          dense
                        ></v-text-field>

                        <v-select
                          :hint="data.physicalActivityLevel ? data.physicalActivityLevel.description : ''"
                          :persistent-hint="!!data.physicalActivityLevel"
                          v-model="data.physicalActivityLevel"
                          label="Physical Activity Level"
                          :rules="[rules.required]"
                          item-text="name"
                          :items="levels"
                          return-object
                          outlined
                          dense
                        ></v-select>
                      </v-col>

                      <v-col v-if="!plan" cols="4">
                        <v-card color="info" class="mb-3">
                          <v-card-text class="white--text">
                            <div class="font-weight-bold mb-3">
                              BMI Categories:
                            </div>
                            Underweight =  &lt; 18.5 <br>
                            Normal weight = 18.5 - 24.9 <br>
                            Overweight = 25 - 29.9 <br>
                            Obesity = BMI of 30 or greater <br>
                          </v-card-text>
                        </v-card>

                        <v-card class="d-none" color="success">
                          <v-card-text class="white--text">
                            <div class="font-weight-bold mb-3">
                              Ideal Body Weight
                            </div>
                            <div class="display-1 font-weight-bold">
                              {{ Math.round(idealWeight) }}kg
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-btn
                    @click="goToPageTwo()"
                    color="accent"
                    depressed
                  >
                    Continue
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-stepper-content> -->

            <!-- RESULT -->
            <v-stepper-content step="1">
              <v-card class="mb-5" flat>
                <v-card-text>
                  <v-form ref="clinicalForm">
                    <v-row>
                      <v-col>
                        <p class="mb-3">Enter the Mealplan Name</p>
                        <v-text-field
                          v-model="mealplanData.name"
                          :rules="[rules.required]"
                          label="Name"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <p class="mb-3">
                      What does your typical meal pattern look like? This is to
                      provide a guide on how many kilojoules to aim for each
                      meal.
                    </p>

                    <v-row>
                      <v-col>
                        <label
                          >Select the types of meals you would eat at least once
                          a week</label
                        >

                        <div class="d-flex gap-12">
                          <v-checkbox
                            label="Breakfast"
                            value="breakfast"
                            v-model="data.mainMeals"
                            multiple
                            dense
                          ></v-checkbox>
                          <v-checkbox
                            label="Lunch"
                            value="lunch"
                            v-model="data.mainMeals"
                            multiple
                            dense
                          ></v-checkbox>
                          <v-checkbox
                            label="Dinner"
                            value="dinner"
                            v-model="data.mainMeals"
                            multiple
                            dense
                          ></v-checkbox>
                          <v-checkbox
                            label="Dessert"
                            value="desert"
                            v-model="data.mainMeals"
                            multiple
                            dense
                          ></v-checkbox>
                        </div>
                      </v-col>
                      <v-col>
                        <p class="mb-3">
                          How many snacks do you want to eat each day?
                        </p>

                        <v-select
                          v-model="data.snacks"
                          :items="[0, 1, 2, 3, 4]"
                          id="goalSnacks"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-alert
                      v-if="
                        (sktbg < 5000 || sctbg < 1200) && user.role == 'member'
                      "
                      type="warning"
                      border="left"
                      text
                    >
                      It is not recommended to consume less than 5000kJ (or
                      1200Cal) per day without professional support. If you wish
                      to find a dietitian, please click this link
                      <a
                        href="https://member.dietitiansaustralia.org.au/Portal/Portal/Search-Directories/Find-a-Dietitian.aspx"
                        target="_blank"
                        >https://member.dietitiansaustralia.org.au/Portal/Portal/Search-Directories/Find-a-Dietitian.aspx</a
                      >.
                    </v-alert>

                    <v-alert
                      v-else-if="
                        (sktbg < 5000 || sctbg < 1200) &&
                        user.role == 'personal_trainer'
                      "
                      type="warning"
                      border="left"
                      text
                    >
                      CAUTION: Providing a meal plan to a client that is less
                      than 5000kJ (1200Cal), requires intensive support from a
                      trained nutrition professional. If suggesting less than
                      5000kJ (1200Cal), please proceed with caution and ensure
                      you are suitably qualified to do so.
                    </v-alert>

                    <v-alert
                      v-else-if="
                        (sktbg < 5000 || sctbg < 1200) &&
                        (user.role == 'dietitian' ||
                          user.role == 'nutritionist' ||
                          user.role == 'naturopath')
                      "
                      type="warning"
                      border="left"
                      text
                    >
                      CAUTION: Providing a meal plan to a client that is less
                      than 5000kJ (1200Cal), requires intensive nutrition
                      support and monitoring. If suggesting less than 5000kJ
                      (1200Cal), please proceed with caution and monitor your
                      client regularly.
                    </v-alert>

                    <p class="mb-3">
                      Suggested energy target based on your goals
                    </p>

                    <v-row>
                      <v-col>
                        <v-text-field
                          :value="$options.filters.numberFormat(sktbg)"
                          suffix="kJ"
                          readonly
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          :value="$options.filters.numberFormat(sctbg)"
                          suffix="Cal"
                          readonly
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <p class="mb-3">
                      The above daily energy requirement is an estimate only. If
                      you have a preferred or measured daily energy target,
                      please add it here in either kilojoules or calories, and
                      we will create a meal plan suited to your needs.
                    </p>

                    <v-row>
                      <v-col>
                        <v-text-field
                          :rules="[
                            (value) =>
                              [
                                'dietitian',
                                'personal_trainer',
                                'nutritionist',
                                'naturopath',
                              ].includes(user.role) ||
                              (!value && value <= 0) ||
                              value >= 5000 ||
                              `Value can't be less than 5000kJ`,
                          ]"
                          @click:clear="deleteCustomTarget(data)"
                          v-model.number="data.customTarget"
                          type="number"
                          suffix="kJ"
                          clearable
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          :value="Math.round((data.customTarget || 0) / 4.18)"
                          @input="data.customTarget = $event * 4.18"
                          type="number"
                          suffix="Cal"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-none">
                      <p class="mb-3">
                        Each of your main meals should contain approximately
                      </p>

                      <v-row>
                        <v-col>
                          <v-text-field
                            :value="
                              $options.filters.numberFormat(mainMealsEnergy)
                            "
                            suffix="kJ"
                            readonly
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <p class="mb-3">
                        Each of your snacks should contain approximately
                      </p>

                      <v-row>
                        <v-col>
                          <v-text-field
                            :value="$options.filters.numberFormat(snacksEnergy)"
                            suffix="kJ"
                            readonly
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>

                    <!-- BUTTONS -->
                    <div>
                      <v-btn
                        :loading="
                          mealplanStatus.adding || mealplanStatus.updating
                        "
                        @click="validateForm()"
                        color="accent"
                        class="mr-2"
                        depressed
                      >
                        {{ editNewMealplan ? 'Update' : 'Add' }}
                      </v-btn>

                      <!-- <v-btn
                        @click="step = 1"
                        :disable="status.creating"
                        text
                      >Back</v-btn> -->
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import rules from '@/rules';
import { mapState, mapGetters, mapActions } from 'vuex';
import { goals, frequencies, levels, patterns, stress } from '@/options';

export default {
  name: 'Goal',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    plan: Object,
    add: {
      type: Boolean,
      default: true,
    },
    editNewMealplan: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      goals,
      rules,
      levels,
      stress,
      step: 1,
      patterns,
      frequencies,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: (state) => state.goal.data,
      goal: (state) => state.goal.goal,
      user: (state) => state.user.user,
      status: (state) => state.goal.status,
      mealplanData: (state) => state.mealplans.data,
      mealplanStatus: (state) => state.mealplans.status,
      currentFolder: (state) => state.mealplans.currentFolder,
    }),

    ...mapGetters('goal', [
      'customDailyEnergyRequirementLose',
      'dailyEnergyRequirementLose',
      'dailyEnergyRequirementCal',
      'dailyEnergyRequirement',
      'weightLossEnergyTarget',
      'energyRequirementsLose',
      'mainMealsEnergy',
      'energyTarget',
      'snacksEnergy',
      'idealWeight',
      'adjusted',
      'eermwCal',
      'eerlwCal',
      'eermwKj',
      'eerlwKj',
      'sctbg',
      'sktbg',
      'bmi',
    ]),

    dailyEnergyRequirementComp: function () {
      return this.dailyEnergyRequirement;
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    goal: function (value) {
      if (value) {
        this.$store.commit('goal/setData', value);
      }
    },

    // 'data': function (value) {
    //   if (value) {
    //     console.log(value)
    //     this.$store.commit('goal/setData', value)
    //   }
    // },

    sktbg: {
      handler(value) {
        if (value < 5000) this.data.customTarget = 5000;
        else this.data.customTarget = 0;
      },
      immediate: true,
    },

    'data.customTarget': function (val) {
      if (val) this.data.customTarget = Math.round(val);
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('goal', [
      'deleteCustomTarget',
      'getGoal',
      'create',
      'update',
    ]),

    ...mapActions('mealplans', ['addPlanToFolder']),

    goToPageTwo() {
      if (this.$refs.goalForm.validate()) {
        this.step = 2;
      }
    },

    goToPageThree() {
      if (this.$refs.parameterForm.validate()) {
        this.step = 3;
      }
    },

    gotoPageFour() {
      if (this.$refs.clinicalForm.validate()) {
        this.step = 4;
      }
    },

    validateForm() {
      if (this.$refs.clinicalForm.validate()) {
        if (!this.editNewMealplan) {
          Promise.resolve(this.$store.dispatch('mealplans/create')).then(
            ({ error, newData }) => {
              if (!error) {
                this.$emit('addedNewMeal', newData);

                setTimeout(() => {
                  Promise.resolve(this.create(newData.id)).then(
                    (response) => {
                      // if (this.$route.name !== 'Home' && !this.plan) {
                      //   this.$router.push({ name: 'Home' })
                      // }
                      this.$emit('closeDialog');
                      if (this.plan) {
                        this.$emit('getId', response.id);
                      }

                      this.addPlanToFolder({
                        id: newData.id,
                        folder: this.currentFolder,
                      });

                      if(this.user?.mealSlots > 0) {
                        this.$store.dispatch('user/updateUserField', {
                          field: 'mealSlots',
                          value: this.user.mealSlots - 1,
                          silent: true,
                        });
                      }

                    },
                    (error) => {
                      this.$store.dispatch('showError', error);
                    }
                  );
                }, 800);
              }
            }
          );
        } else {
          Promise.resolve(
            this.$store.dispatch('mealplans/updateName', this.plan)
          ).then(() => {
            Promise.resolve(this.update()).then(() => {
              // this.$store.commit('mealplans/renameCurrentFolderClient', this.goal.client)
              if (this.plan.requestStatus == 'approved') {
                Promise.resolve(
                  this.$store.dispatch('mealplans/updateMealField', {
                    value: 'edited',
                    field: 'requestStatus',
                    meal: this.plan,
                    silent: true,
                  })
                ).then(() => {
                  this.$emit('closeDialog');
                });
              } else {
                this.$emit('closeDialog');
              }

              // if (!response && this.$route.name !== 'Home' && !this.plan) {
              //   this.$router.push({ name: 'Home' })
              // }
              // else if (this.plan) {
              // }
            });
          });
        }
      }
    },

    setGoal() {
      if (this.editNewMealplan) this.getGoal(this.plan ? this.plan.id : null);
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.editNewMealplan) this.getGoal(this.plan ? this.plan.id : null);
  },
};
</script>
